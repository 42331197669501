var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{staticStyle:{"border-bottom":"1px solid var(--v-foreground-5-base)"},attrs:{"hide-slider":"","active-class":"primary white--text","background-color":"transparent","height":"36"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(_vm._s(_vm.$t('tables_create.create')))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t('tables_create.duplicate')))])],1),_c('v-tabs-items',{staticStyle:{"background-color":"transparent"},attrs:{"dark":_vm.isDark},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card-text',{staticStyle:{"padding":"0px"}},[_c('MutationForm',{attrs:{"no-reset":"","mutation":"createTable","constants":{ environmentId: _vm.environmentId },"submitButtonText":_vm.$t('tables_create.create').toString(),"submitButtonIcon":"add","environment-variables":{ environmentId: _vm.environmentId },"schema":{
            environmentId: {
              __graphQLType: 'ID'
            },
            name: {
              label: _vm.$t('tables_create.schema.name.label'),
              type: 'string',
              description: _vm.$t('tables_create.schema.name.description'),
              newDesign: true,
              isDark: _vm.isDark
            },
            title: {
              label: _vm.$t('tables_create.schema.title.label'),
              type: 'string',
              optional: true,
              description: _vm.$t('tables_create.schema.title.description'),
              newDesign: true,
              isDark: _vm.isDark
            },
            collectionId: {
              label: _vm.$t('tables_create.schema.collectionId.label'),
              type: 'string',
              fieldType: 'collectionSelect',
              __graphQLType: 'ID',
              description: _vm.$t(
                'tables_create.schema.collectionId.description'
              ),
              newDesign: true,
              isDark: _vm.isDark
            }
          }},on:{"success":function($event){return _vm.$emit('create', $event)},"error":function($event){return _vm.$emit('error', $event)}}},[_c('v-btn',{staticClass:"rounded-sm",staticStyle:{"border":"2px solid var(--v-primary-base)"},attrs:{"slot":"left-buttons","outlined":""},on:{"click":function($event){return _vm.$emit('dismiss')}},slot:"left-buttons"},[_vm._v(_vm._s(_vm.$t('tables_create.cancelBtn')))])],1)],1)],1),_c('v-tab-item',[_c('v-card-text',{staticClass:"pa-0"},[_c('div',{staticClass:"pa-4"},[_c('div',{staticClass:"overline"},[_vm._v(_vm._s(_vm.$t('tables_create.duplicates')))]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t('tables_create.whatDuplicate'))+" ")]),_c('div',{staticClass:"overline"},[_vm._v(_vm._s(_vm.$t('tables_create.WontDupli')))]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t('tables_create.whatWontDupli1')))]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t('tables_create.whatWontDupli2'))+" ")])]),_c('MutationForm',{attrs:{"no-reset":"","mutation":"duplicateTable","environmentVariables":{ environmentId: _vm.environmentId },"submitButtonText":_vm.$t('tables_create.dupliBtn').toString(),"submitButtonIcon":"content_copy","schema":{
            tableId: {
              __graphQLType: 'ID',
              label: _vm.$t('tables_create.schema.tableId.label'),
              fieldType: 'componentSelect',
              fieldOptions: {
                componentTypeName: 'tables'
              },
              newDesign: true
            },
            newName: {
              label: _vm.$t('tables_create.schema.newName.label'),
              type: 'string',
              description: _vm.$t('tables_create.schema.newName.description'),
              newDesign: true,
              isDark: _vm.isDark
            }
          }},on:{"success":function($event){return _vm.$emit('create', $event)},"error":function($event){return _vm.$emit('error', $event)}}},[_c('v-btn',{staticClass:"rounded-sm",staticStyle:{"border":"2px solid var(--v-primary-base)"},attrs:{"slot":"left-buttons","outlined":""},on:{"click":function($event){return _vm.$emit('dismiss')}},slot:"left-buttons"},[_vm._v(_vm._s(_vm.$t('tables_create.cancelBtn')))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }